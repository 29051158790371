import { Save24 } from "@carbon/icons-react";
import { Button, FileUploader, Form, TextInput, DatePicker, DatePickerInput, Toggle } from "carbon-components-react";
import { useEffect, useState } from "react";
import { instance } from "../../services/axios";
import { useParams } from "react-router";
import { Grid } from "carbon-components-react";
import { Row } from "carbon-components-react";
import { Column } from "carbon-components-react";
import ImagePreview from "../../components/ImagePreview";
import { InlineLoading } from "carbon-components-react";
import { convertImageToBase64, formatDateLabel } from "../../services/utils";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import Compressor from 'compressorjs';

export const ExhibitionFormComponent = () => {

  const history = useHistory();

  const [exhibition, setExhibition] = useState({
    artist: null,
    cocurator: null,
    curator: null,
    description: null,
    documentId: null,
    dateLabel: null,
    exhibitionStartDate: null,
    exhibitionEndDate: null,
    imageFilename: null,
    imageBase64: null,
    isActive: null,
    title: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const populateField = (field, value) => {
    setExhibition({ ...exhibition, [field]: value });
  };

  const [isValidImageSelected, setIsValidImageSelected] = useState(true);

  useEffect(() => {
    if (!isValidImageSelected)
      document.querySelector("#imageField > div").innerHTML = "";
  }, [isValidImageSelected])

  useEffect(() => {
    (async () => {
      try {
        const response = await instance.get(`exhibition/${id}`);
        if (response) {
          setExhibition({ ...exhibition, ...response.data,
            exhibitionStartDate: response.data.exhibitionStartDate ? new Date(response.data.exhibitionStartDate) : null,
            exhibitionEndDate: response.data.exhibitionEndDate ? new Date(response.data.exhibitionEndDate) : null,
           });
        }
      } catch (e) {
        console.error(e);
        setExhibition([]);
      }
      setLoading(false);
    })();
    return () => { };
  }, [id]);

  const save = async (e) => {
    e.preventDefault();

    if(!exhibition.exhibitionStartDate || !exhibition.exhibitionEndDate){
      alert("Por favor, selecione as datas de início e fim.");
      return;
    }

    setIsSubmitting(true);
    let sendData = exhibition;
    
    const dateLabel = formatDateLabel(sendData.exhibitionStartDate, sendData.exhibitionEndDate);
    sendData = { ...sendData, "dateLabel": dateLabel }

    // const saved = await instance.post("/exhibition", sendData).response.data;
    const saved = await instance.post("/exhibition", sendData);

    console.log(saved)

    setSuccess(!!saved.data);
    setExhibition(saved.data);
    setIsSubmitting(false);
    setTimeout(() => {
      setSuccess(false);
      history.push("/exposicoes");
    }, 2000);
  };

  return (
    <>
      <Form onSubmit={(e) => e.preventDefault()}>
        {" "}
        <Grid>
          <h1>{exhibition.documentId? 'Editar exposições' : 'Nova exposição'}</h1> 
          <Row>
            <Column lg={7}>
              <TextInput
                type="text"
                required
                id="title"
                labelText="Título"
                placeholder="Título"
                defaultValue={exhibition.title}
                onChange={(e) => populateField("title", e.target.value)}
              />
              <br />
              <TextInput
                type="text"
                required
                id="artist"
                labelText="Artista"
                placeholder="Artista"
                defaultValue={exhibition.artist}
                onChange={(e) => populateField("artist", e.target.value)}
              />
              <br />
              <TextInput
                type="text"
                required
                id="curator"
                labelText="Curador"
                placeholder="Curador"
                defaultValue={exhibition.curator}
                onChange={(e) => populateField("curator", e.target.value)}
              />
              <br />
              <TextInput
                type="text"
                required
                id="cocurator"
                labelText="Cocurador"
                placeholder="Cocurador"
                defaultValue={exhibition.cocurator}
                onChange={(e) => populateField("cocurator", e.target.value)}
              />
              <br />
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
                value={Date.parse(exhibition.exhibitionStartDate)}
                onChange={(e) => populateField("exhibitionStartDate", new Date(e))}
                allowInput={false}>
                <DatePickerInput
                  id="exhibitionStartDate"
                  placeholder="dd/mm/aaaa"
                  labelText="Data de início da exposição"
                  size='md'
                  required
                />
              </DatePicker>
              <br />
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
                value={Date.parse(exhibition.exhibitionEndDate)}
                onChange={(e) => populateField("exhibitionEndDate", new Date(e))}
                allowInput={false}>
                <DatePickerInput
                  id="exhibitionEndDate"
                  placeholder="dd/mm/aaaa"
                  labelText="Data de fim da exposição"
                  size='md'
                  required
                />
              </DatePicker>
              <br />
              <ReactQuill
                id="description"
                value={exhibition.description || ''}
                theme="snow" 
                onChange={(value) => populateField("description", value)}
                placeholder="Descrição"
              />
              <br />
              <Toggle
                labelText="Em divulgação"
                labelA="Não"
                labelB="Sim"
                id="isActive"
                onToggle={(e) => populateField("isActive", e)}
                toggled={exhibition.isActive}
                />
            </Column>
            <Column lg={5} align="left">
              <FileUploader
                accept={[".jpg", ".png"]}
                buttonKind="tertiary"
                buttonLabel="Escolher imagem"
                labelTitle="Imagem"
                labelText="Escolher imagem"
                labelDescription="Arquivos .jpg ou .png de até 1MB"
                name="image"
                id="imageField"
                filenameStatus="edit"
                iconDescription="Delete file"
                onChange={(e) => {
                  const selectedFile = e.target.files[0];

                  // Check if the file is a valid image (jpg or png)
                  const validFileTypes = ["image/jpeg", "image/png"];
                  if (!validFileTypes.includes(selectedFile.type)) {
                    alert("Por favor, selecione um arquivo .jpg ou .png.");
                    setIsValidImageSelected(false);
                    return;
                  }

                  if (selectedFile.size > 1048576) {
                    alert("O arquivo selecionado excede o limite de 1 MB.");
                    setIsValidImageSelected(false);
                    return;
                  }

                  // Compress the image before converting to Base64
                  new Compressor(selectedFile, {
                    quality: 0.7, // Adjust the compression quality between 0 (lowest) and 1 (highest)
                    success(compressedFile) {
                      // Convert the compressed image to Base64
                      convertImageToBase64(compressedFile).then((base64String) => {
                        setIsValidImageSelected(true);
                        populateField("imageBase64", base64String);
                      });
                    },
                    error(err) {
                      console.log("Image compression error: ", err.message);
                    }
                  });
                }}
                onDelete={() => {
                  populateField("imageBase64", null);
                }}
              />
              {exhibition.imageBase64 ? <ImagePreview image={exhibition.imageBase64} /> : null}
            </Column>
          </Row>
          <Row>
            <Column lg={7} align="left">
              <br />
              {isSubmitting || success ? (
                <div style={{ display: "inline-block" }}>
                  <InlineLoading
                    description={success ? "Salvo com sucesso" : "Salvando..."}
                    status={success ? "finished" : "active"}
                  />
                </div>
              ) : (
                <Button type="submit" renderIcon={Save24} onClick={save}>
                  Salvar
                </Button>
              )}
            </Column>
          </Row>
        </Grid>
      </Form>
    </>
  );
};
