import {
  Button,
  Form,
  TextInput,
  Grid,
  Row,
  Column,
  FileUploader,
  DatePicker, 
  DatePickerInput,
  Toggle
} from "carbon-components-react";
import { Save24 } from "@carbon/icons-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { instance } from "../../services/axios";
import ImagePreview from "../../components/ImagePreview";
import { InlineLoading } from "carbon-components-react";
import { convertImageToBase64, formatDateLabel } from "../../services/utils";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import Compressor from 'compressorjs';

export const ActivityFormComponent = () => {
  const history = useHistory();

  const [activity, setActivity] = useState({
    activityStartDate: null,
    activityEndDate: null,
    dateLabel: null,
    description: null,
    documentId: null,
    imageFilename: null,
    imageBase64: null,
    isActive: null,
    title: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const populateField = (field, value) => {
    setActivity({ ...activity, [field]: value });
  };

  const [isValidImageSelected, setIsValidImageSelected] = useState(true);

  useEffect(() => {
    if (!isValidImageSelected)
      document.querySelector("#imageField > div").innerHTML = "";
  }, [isValidImageSelected])

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await instance.get(`activity/${id}`);
        if (response) {
          setActivity({ ...activity, ...response.data,
            activityStartDate: response.data.activityStartDate ? new Date(response.data.activityStartDate) : null,
            activityEndDate: response.data.activityEndDate ? new Date(response.data.activityEndDate) : null,
           });
        }
      } catch (e) {
        console.error(e);
        setActivity([]);
      }
      setLoading(false);
    })();
    return () => {};
  }, [id]);

  const save = async (e) => {
    e.preventDefault();

    if (!activity.activityStartDate || !activity.activityEndDate) {
      alert("Por favor, selecione as datas de início e fim.");
      return;
    }

    setIsSubmitting(true);
    let sendData = activity;
    const dateLabel = formatDateLabel(sendData.activityStartDate, sendData.activityEndDate);
    sendData = { ...sendData, "dateLabel": dateLabel }
    
    const saved = await instance.post("/activity", sendData);
    setSuccess(!!saved.data);
    setActivity(saved.data);
    setIsSubmitting(false);
    setTimeout(() => {
      setSuccess(false);
      history.push("/atividades");
    }, 2000);
  };

  return (
    <>
      <Form onSubmit={(e) => e.preventDefault()}>
        <Grid>
          <h1>{activity.documentId? 'Editar atividade' : 'Nova atividade'}</h1>
          <Row>
            <Column lg={7}>
              <TextInput
                type="text"
                required
                id="title"
                labelText="Título"
                placeholder="Título"
                defaultValue={activity.title}
                onChange={(e) => populateField("title", e.target.value)}
              />
              <br />
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
                value={Date.parse(activity.activityStartDate)}
                onChange={(e) => populateField("activityStartDate", new Date(e))}
                allowInput={false}>
                <DatePickerInput
                  id="activityStartDate"
                  placeholder="dd/mm/aaaa"
                  labelText="Data de início da atividade *"
                  size='md'
                  required
                />
              </DatePicker>
              <br />
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
                value={Date.parse(activity.activityEndDate)}
                onChange={(e) => populateField("activityEndDate", new Date(e))}
                allowInput={false}>
                <DatePickerInput
                  id="activityEndDate"
                  placeholder="dd/mm/aaaa"
                  labelText="Data de fim da atividade *"
                  size='md'
                  required
                />
              </DatePicker>
              <br />
              <ReactQuill
                id="description"
                value={activity.description || ''}
                theme="snow" 
                onChange={(value) => populateField("description", value)}
                placeholder="Descrição"
              />
              <br />
              <Toggle
                labelText="Em divulgação"
                labelA="Não"
                labelB="Sim"
                id="isActive"
                onToggle={(e) => populateField("isActive", e)}
                toggled={activity.isActive}
                />
            </Column>
            <Column lg={5} align="left">
            <FileUploader
                accept={[".jpg", ".png"]}
                buttonKind="tertiary"
                buttonLabel="Escolher imagem"
                labelTitle="Imagem"
                labelText="Escolher imagem"
                labelDescription="Arquivos .jpg ou .png de até 1MB"
                name="image"
                id="imageField"
                filenameStatus="edit"
                iconDescription="Delete file"
                onChange={(e) => {
                  const selectedFile = e.target.files[0];

                  // Check if the file is a valid image (jpg or png)
                  const validFileTypes = ["image/jpeg", "image/png"];
                  if (!validFileTypes.includes(selectedFile.type)) {
                    alert("Por favor, selecione um arquivo .jpg ou .png.");
                    setIsValidImageSelected(false);
                    return;
                  }

                  if (selectedFile.size > 1048576) {
                    alert("O arquivo selecionado excede o limite de 1 MB.");
                    setIsValidImageSelected(false);
                    return;
                  }

                  // Compress the image before converting to Base64
                  new Compressor(selectedFile, {
                    quality: 0.7, // Adjust the compression quality between 0 (lowest) and 1 (highest)
                    success(compressedFile) {
                      // Convert the compressed image to Base64
                      convertImageToBase64(compressedFile).then((base64String) => {
                        setIsValidImageSelected(true);
                        populateField("imageBase64", base64String);
                      });
                    },
                    error(err) {
                      console.log("Image compression error: ", err.message);
                    }
                  });
                }}
                onDelete={() => {
                  populateField("imageBase64", null);
                }}
              />
              {activity.imageBase64 ? <ImagePreview image={activity.imageBase64} /> : null}
            </Column>
          </Row>
          <Row>
            <Column lg={7} align="left">
              <br />
              {isSubmitting || success ? (
                <div style={{ display: "inline-block" }}>
                  <InlineLoading
                    description={success ? "Salvo com sucesso" : "Salvando..."}
                    status={success ? "finished" : "active"}
                  />
                </div>
              ) : (
                <Button type="submit" renderIcon={Save24} onClick={save}>
                  Salvar
                </Button>
              )}
            </Column>
          </Row>
        </Grid>
      </Form>
    </>
  );
};
