import HeaderComponent from "../components/Header";

const SecuredLayout = ({ children, ...props }) => (

  <>
    <HeaderComponent isAuthenticated={props.isAuthenticated} setIsAuthenticated={props.setIsAuthenticated} />
    {children}
  </>
);

export default SecuredLayout;
