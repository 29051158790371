import {
  Header,
  HeaderMenuButton,
  HeaderName,
  HeaderNavigation,
  HeaderContainer,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
  HeaderGlobalBar,
} from "carbon-components-react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import LogoutButton from "../LogoutButton";

const HeaderComponent = ({ isAuthenticated, setIsAuthenticated }) => {

  const PlatformName = "App Admin";
  const location = useLocation();

  const links = [
    { label: "Home", url: "/" },
    { label: "Exposições", url: "/exposicoes", suburl: "/nova-exposicao" },
    { label: "Atividades", url: "/atividades", suburl: "/nova-atividade" },
    // { label: "Créditos", url: "/creditos" },
    { label: "Informações ao público", url: "/sobre" },
  ];
  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label={PlatformName}>
          <SkipToContent />
          <HeaderMenuButton
            aria-label="Open menu"
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <HeaderName prefix="Iberê" href="#">
            {PlatformName}
          </HeaderName>

          {isAuthenticated &&
            <>
              <HeaderNavigation aria-label={PlatformName}>
                {links.map((link) => (
                  <Link
                    key={link.label}
                    className={
                      "bx--header__menu-item " +
                      (link.url === location.pathname ||
                        link.suburl === location.pathname
                        ? "bx--header__menu-item--current"
                        : "")
                    }
                    to={link.url}
                  >
                    {link.label}
                  </Link>
                ))}
              </HeaderNavigation>
            </>}




          <SideNav
            aria-label="Side navigation"
            expanded={isSideNavExpanded}
            isPersistent={false}
          >
            <SideNavItems>
              <HeaderSideNavItems>
                {links.map((link) => (
                  <Link
                    key={link.label}
                    className={
                      "bx--header__menu-item " +
                      (link.url === location.pathname ||
                        link.suburl === location.pathname
                        ? "bx--header__menu-item--current"
                        : "")
                    }
                    to={link.url}
                  >
                    {link.label}
                  </Link>
                ))}
              </HeaderSideNavItems>
            </SideNavItems>
          </SideNav>
          <HeaderGlobalBar>
            {isAuthenticated ? <LogoutButton setIsAuthenticated={setIsAuthenticated} /> : <div></div>}
          </HeaderGlobalBar>
        </Header>
      )}
    />
  );
};

export default HeaderComponent;
