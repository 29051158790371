import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const config = {
  apiKey: "AIzaSyBjk7w9xFwyi3EVKGLlhxSWbPyR62TV4Nw",
  authDomain: "fundacao-ibere.firebaseapp.com",
  databaseURL: "https://fundacao-ibere.firebaseio.com",
  projectId: "fundacao-ibere",
  storageBucket: "fundacao-ibere.appspot.com",
  messagingSenderId: "682849362266",
  appId: "1:682849362266:web:9499ea446a7db6c8d15363"
};
firebase.initializeApp(config);

export const auth = firebase.auth();
export default firebase;