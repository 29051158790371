import { useEffect, useRef } from "react";

const ImagePreview = ({ image }) => {
  const imageRef = useRef(null);

  const previewStyle = {
    maxWidth: "100%",
    maxHeight: "250px",
    marginTop: "10px",
  };

  useEffect(() => {
    if (image) {
      const preview = imageRef.current;
      preview.src = image
      preview.onload = () => URL.revokeObjectURL(preview.src);
    }
  }, [image]);

  return (
    image && (
      <div>
        <img ref={imageRef} style={previewStyle} alt="preview" />
      </div>
    )
  );
};

export default ImagePreview;
