export const stripHTML = (src) => src.replace(/(<([^>]+)>)/gi, "");

export function convertImageToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  export function formatDateLabel(initDate, endDate) {
    const months = [
        "jan", "fev", "mar", "abr", "mai", "jun",
        "jul", "ago", "set", "out", "nov", "dez"
    ];

    const start= new Date(initDate);
    const end= new Date(endDate);
    const startDay = start.getDate();
    const endDay = end.getDate();
    const startMonth = months[start.getMonth()];
    const endMonth = months[end.getMonth()];
    const year = end.getFullYear().toString().slice(2);

    const startLabel = `${startDay}.${startMonth}`
    const endLabel = `${endDay}.${endMonth}.${year}`
    return `${startLabel} - ${endLabel}`;
}
