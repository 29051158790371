import axios from "axios";
import { auth } from "../firebase";

export const instance = axios.create({
    baseURL: "https://teste-api.iberecamargo.org.br/",
    withCredentials: true, // Allow credentials such as cookies to be sent with cross-origin requests
  });

instance.interceptors.request.use(async (request) => {

  const { currentUser } = auth;
  
  request.headers = {
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    Authorization: `Bearer ${await currentUser.getIdToken()}`,
  };
  return request;

});