import { Redirect } from "react-router-dom";

const Protected = ({ isAuthenticated, children }) => {

    return (
        <>
            {isAuthenticated && children}
            {isAuthenticated == false && <Redirect to="/" replace />}
            
        </>
    )
};

export default Protected;