import {
  Button,
  //  ButtonSet,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "carbon-components-react";
import { Edit24 } from "@carbon/icons-react";
import { instance } from "../../services/axios";
import { useEffect, useState } from "react";

export const CreditsComponent = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const responseRows = await instance.get("credits/");
        if (responseRows) {
          setRows(responseRows.data);
        }
      } catch (e) {
        console.error(e);
        setRows([]);
      }
    })();
    return () => {};
  }, []);

  const headers = ["Name", "Rule", "Status"];

  return (
    <>
      <h1>Créditos</h1>
      {/* <ButtonSet style={{ paddingBottom: "20px" }}>
        <Button kind="primary">Novo crédito</Button>
      </ButtonSet> */}
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableHeader key={header}>{header}</TableHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.title}>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.artist || "-"}</TableCell>
              <TableCell>{row.dateLabel}</TableCell>
              <TableCell style={{ textAlign: "right" }}>
                <Button
                  renderIcon={Edit24}
                  iconDescription="Editar"
                  hasIconOnly
                  onClick={() => true}
                />
                &nbsp;
                {/* <Button
                  kind="danger"
                  renderIcon={Delete24}
                  iconDescription="Remover"
                  hasIconOnly
                  onClick={() => true}
                /> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
